import React from 'react'
import UnderPackageShow from '../../../components/Feedback&Complaints/FeedbackGiftPackage/Display/DisplayUnderPackage/UnderPackageFeedbacks';

function FeedbackDisplayUnderPackage() {
  return (
    <div>
        <UnderPackageShow/>
    </div>
  )
}

export default FeedbackDisplayUnderPackage