import React from "react";
import ProductSummary from "../../../components/Feedback&Complaints/Feedback/Display/Summary/FeedbackSummary";

const Summary = () => {
  return (
    <div>
      <ProductSummary />
    </div>
  );
};

export default Summary;
