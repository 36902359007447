import React from 'react'
import SellerCheckout from '../../../components/sellerPartnership/sellerCheckout/SellerCheckout'

function SellerCheckoutPage() {
  return (
    <>
    <div>SellerCheckout</div>
    <SellerCheckout/>
    </>
  )
}

export default SellerCheckoutPage