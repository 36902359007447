import React from 'react'
import './staffDashboardHome.css';

function StaffDashboardHome() {
  return (
    <div>
      <div className="staff-dashboard-sample-text">
        <h1>Staff wholesale orders Dashboard</h1>
        <p>Welcome to your wholesale dashboard</p>
      </div>
    </div>
  )
}

export default StaffDashboardHome