import React from 'react'
import './Allproduct.css'
import ProductList from '../../../components/sellerPartnership/productList/ProductList'

function AllProduct() {
  return (
    <>
    <div className='seller-AllProduct'>
    <ProductList/>
    </div>
    </>
  )
}

export default AllProduct