import Login from '../../../components/common/login/Login';
import "./loginPage.css";

function LoginPage() {
  return (
    <Login/>
  )
}

export default LoginPage
