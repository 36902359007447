import React from 'react'
import './sellerAppointmentPage.css'
import SellerAppointment from '../../../components/sellerPartnership/appointment/SellerAppointment'

function SellerAppointmentPage() {
  return (
    <>
    <div className='seller-AppointmentPage'>
    <SellerAppointment/>
    </div>
    </>
  )
}

export default SellerAppointmentPage