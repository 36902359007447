import React from 'react'
import './sellerProfilePage.css'
import SellerProfile from '../../../components/sellerPartnership/Profile/SellerProfile'

function SellerProfilePage() {
  return (
    <>
    <div className='seller-ProfilePage'>
    <SellerProfile/>
    </div>
    </>
  )
}

export default SellerProfilePage