import './staffInventoryDashboardHome.css';


function StaffInventoryDashboardHome() {
  return (
    <div className="staff-dashboard-sample-text">
        <h1>Staff Inventory Dashboard</h1>
        <p>Welcome to your Inventory dashboard</p>
    </div>
  )
}

export default StaffInventoryDashboardHome