import './orderStaffDashboard.css';

function OrderStaffDashboard() {
  return (
    <div>
      <div className="staff-dashboard-sample-text">
        <h1>Staff Retail orders Dashboard</h1>
        <p>Welcome to your retail dashboard</p>
      </div>
    </div>
  )
}

export default OrderStaffDashboard