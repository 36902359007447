import React from 'react'
import './producctDetail.css'
import SingleProduct from '../../../components/sellerPartnership/singleProduct/SingleProduct'

function ProductDetail() {
  return (
    <>
    <div className='seller-productDetailPage'>
    <h1 >ProductDetail</h1>
    <SingleProduct/>
    </div>
    </>
  )
}

export default ProductDetail